.loading-spinner {
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
}

.loading-spinner-small {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

/* Homage Alert Dialog styles */

.ha-dialog-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3002;
    background: rgba(0, 0, 0, 0.36);
    display: flex;
    align-items: center;
    justify-content: center;
    animation-name: fade-in;
    animation-duration: 0.2s;
    color: #22234e;
}

.ha-dialog {
    background: #fff;
    padding: 11px;
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12),
        0 11px 15px -7px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    max-width: 95vw;
    max-height: 90%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.ha-dialog-appear {
    animation-duration: 0.2s;
    animation-name: dialog-appear;
}

.search-cr-dialog {
    background: rgb(116, 42, 42);
    padding: 0px;
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12),
        0 11px 15px -7px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    max-width: 95vw;
    /* height: 50px; */
    max-height: 90%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 50;
}

.search-cr-dialog-appear {
    animation-duration: 0.2s;
    animation-name: dialog-appear;
}

.ha-dialog-title {
    font-weight: bold;
    margin-bottom: 16px;
}

.ha-dialog-body {
    font-size: 14px;
    padding-right: 10px;
}

.ha-dialog-cancel {
    color: #a8a8a8 !important;
    text-transform: none !important;
    height: 32px;
    margin-top: 10px !important;
    font-weight: 500 !important;
}

.ha-dialog-done {
    color: #37bac4 !important;
    text-transform: none !important;
    height: 32px;
    margin-top: 10px !important;
    font-weight: 500 !important;
    margin-left: 4px;
}

.ha-dialog-heading {
    color: #22234e;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    margin-bottom: 3px;
}

.ha-dialog-text {
    color: #757588;
    font-size: 14px;
    line-height: 19px;
    margin-top: 3px;
    margin-bottom: 40px;
}

.ha-dialog-line {
    height: 1px;
    border-top: 1px solid #dbe1e8;
}

.ha-dialog-anim {
    height: 139px;
    max-width: 100%;
    overflow: hidden;
    margin-bottom: 16px;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes dialog-appear {
    from {
        opacity: 0;
        transform: scale(0.7);
    }
    to {
        opacity: 1;
        transform: none;
    }
}

/* End Homage Alert Dialog styles */

.popin-anim {
    animation-duration: 0.2s;
    animation-name: anim-popin;
}

@keyframes anim-popin {
    from {
        opacity: 0.5;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: none;
    }
}

.appear-down {
    animation-duration: 0.2s;
    animation-name: appear-down;
    transform-origin: top;
}

@keyframes appear-down {
    from {
        opacity: 0.2;
        transform: scaleX(0.98) scaleY(0.9);
    }
    to {
        opacity: 1;
        transform: none;
    }
}

@keyframes appear-from-bottom {
    from {
        opacity: 0.5;
        transform: translateY(70px);
    }
    to {
        opacity: 1;
        transform: none;
    }
}

/* .scroll-to-top {
    position: fixed;
    bottom: 12px;
    right: 12px;
    height: 40px;
    width: 40px;
    background-color: rgba(50, 189, 199, 0.61);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    cursor: pointer;
    animation-duration: 0.2s;
    animation-name: appear-from-bottom;
}

.scroll-to-top:hover {
    background-color: rgba(42, 160, 169, 0.61);
}

.scroll-to-top:hover img {
    margin-bottom: 2px;
} */

.app {
    width: 100%;
    height: 100%;
}

.navigation-bar {
    border: 1px solid #32bdc7;
    background-color: #32bdc7;
    height: 3.25rem;
    padding: 0 1.25rem;
    box-sizing: border-box;
    flex-shrink: 0;
}

.navigation-bar .homage-logo {
    width: 1.875rem;
    margin-right: 0.625rem;
    cursor: pointer;
}

.navigation-bar .homage-title {
    color: #fff;
    font-size: 0.875rem;
    font-weight: bold;
}

.app-section {
    width: 100%;
    flex-direction: row;
    display: flex;
    /*enables flex content for its children*/
    box-sizing: border-box;
}

.content-section {
    width: 100%;
}

.sidebar-menu + .content-section {
    width: calc(100% - 230px);
}

.sidebar-collapsed + .content-section {
    width: calc(100% - 50px);
}

.logout {
    text-align: center;
    border-radius: 4px;
    border: 1px solid #fff;
    color: #fff;
    margin-left: auto;
    width: 100px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.app-spinner-cont {
    border-radius: 4px;
    background: #fbfbfb;
    padding: 10px;
    padding-bottom: 6px;
}

.app .loading-spinner {
    z-index: 3005;
}

.app-snackbars {
    z-index: 3004;
}

button {
    background: transparent;
}

.no-select,
svg {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

*[hidden],
*[data-hid],
.gone {
    display: none !important;
}

.invisible {
    opacity: 0;
}

a {
    text-decoration: none;
}

.light {
    font-weight: 300;
}

.mb {
    font-weight: 500;
}

.b {
    font-weight: 700;
}

.vertical,
.v {
    display: flex;
    flex-direction: column;
}

.horizontal,
.h {
    display: flex;
    flex-direction: row;
}

.center-justified,
.c {
    justify-content: center;
}

.justified,
.j {
    justify-content: space-between;
}

.end-justified,
.ej {
    justify-content: flex-end;
}

.wrap,
.w {
    flex-wrap: wrap;
}

.vertical-center,
.vc {
    align-items: center;
}

.fs {
    align-items: flex-start;
}
.asfs {
    align-self: flex-start;
}

.fe {
    align-items: flex-end;
}

.grow,
.g {
    flex-grow: 1;
}

.center {
    align-items: center;
}

.center-text {
    text-align: center;
}

.hcenter {
    justify-content: center;
}

.whitebox {
    background: #fff;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-left: 0.5rem;
    margin-bottom: 0.8125rem;
    box-shadow: 1px 1px #eee;
}

.arrow {
    outline: none;
    width: 2.125rem;
    height: 2.125rem;
    display: block;
    margin: 0.5rem;
    user-select: none;
    cursor: pointer;
    padding: 0.75rem;
    box-sizing: border-box;
    border-radius: 50%;
    background: transparent;
    transition: background 0.5s;
}

.disable,
*[data-disable] {
    pointer-events: none;
    opacity: 0.5 !important;
    cursor: default;
}

.arrow:active,
.arrow:hover {
    background: #f5f5f5;
}

.arrow.end {
    transform: scaleX(-1);
}

.component-title {
    color: #4a4a4a;
    font-size: 1.5rem;
    font-weight: 500;
}

.nav-links {
    margin-right: 0.625rem;
}

.nav-links > a {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    color: #4a4a4a;
    font-size: 0.75rem;
    white-space: nowrap;
}

.nav-links > .arrow {
    pointer-events: none;
    opacity: 0.4;
    margin: 0;
}

.component-whitebox {
    border: 1px solid #ececec;
    border-radius: 5px;
    background-color: #fff;
    margin-top: 1rem;
}

.component-whitebox.paper-padding {
    padding: 10px 0px 10px 10px;
}

.avatar {
    width: 4.875rem;
    min-width: 4.875rem;
    height: 4.875rem;
    margin-right: 1.13875rem;
    object-fit: cover;
}

.round {
    border-radius: 50%;
}

.singleline {
    white-space: nowrap;
}

.elipsis,
.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.homage-green-text {
    color: #32bdc7;
}

.no-overflow {
    overflow: hidden;
}

.auto-overflow {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.MuiFormLabel-root.Mui-focused {
    color: #33bdc7 !important;
}

.MuiOutlinedInput-notchedOutline {
    border-color: #dbe1e8;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #33bdc7 !important;
    /* border-width: 1px; */
}

.MuiCircularProgress-colorPrimary {
    color: #33bdc7 !important;
}

.MuiButton-containedPrimary {
    color: #fff !important;
    background-color: #32bdc7 !important;
}

.MuiButton-containedPrimary.Mui-disabled {
    color: #fff !important;
    box-shadow: none !important;
    background-color: rgb(164, 164, 164, 0.6) !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.25);
}

.dropdown-list {
    overflow-y: auto;
    overflow-x: hidden;
    /* scroll-behavior: smooth; */
}

.dropdown-item {
    min-width: 150px;
    padding: 0 12px;
    line-height: 40px;
    cursor: pointer;
}

.dropdown-item > span {
    color: #22234e;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.dropdown-item[item-selected] {
    background-color: #f1f3f4;
}

.dropdown-item[item-hover] {
    background-color: #eceff1;
}

.no-shrink {
    flex-shrink: 0;
}

/* Homage green button */

.ha-btn-green {
    background: #32bdc7 !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 1.5 !important;
    text-transform: none !important;
    box-shadow: none !important;
    color: #fff !important;
}

.ha-btn-green:hover {
    box-shadow: 1px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
}

.ha-dlg-ok {
    padding: 14px 24px;
    font-size: 16px !important;
    white-space: nowrap;
    border-radius: 8px;
}

.ha-dlg-cancel {
    padding: 14px 24px;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 1.5 !important;
    text-transform: none !important;
    box-shadow: none !important;
    color: #9898a6 !important;
    white-space: nowrap;
}

/* End Homage green button */

/* Homage radio button style */

.ha-rb .MuiRadio-colorSecondary.Mui-checked {
    color: #32bdc7 !important;
}

.ha-rb .MuiIconButton-colorSecondary:hover,
.ha-rb .MuiRadio-colorSecondary.Mui-checked:hover {
    background-color: rgba(50, 189, 199, 0.08) !important;
}
.ha-rb .MuiTypography-body1 {
    color: #22234e;
    font-size: 14px;
    line-height: 20px;
    padding-top: 1px;
}

/* Homage radio button style */

/* Native green bordered buttons */

.green-border-btn,
.green-border-icon-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    outline: none;
    border: 1px solid #32bdc7;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 700;
    color: #32bdc7;
    cursor: pointer;
    transition: background 0.2s ease-out;
    min-height: 42px;
}

.green-border-btn {
    min-width: 131px;
    justify-content: center;
    padding: 10px 0px;
}

.green-border-btn[data-selected] {
    background: #84d7dd;
    color: #fff;
}

.green-border-icon-btn {
    padding: 17px 20px;
}

.green-border-icon-btn img {
    width: 40px;
    height: 40px;
    margin-right: 20px;
}

.green-border-icon-btn[data-selected] {
    background: #32bdc7;
    color: #fff;
}

.green-border-icon-btn[data-selected] img {
    filter: brightness(0) invert(1);
}

.green-border-icon-btn:hover,
.green-border-icon-btn:focus,
.green-border-btn:hover,
.green-border-btn:focus {
    background: #e8fcff;
}

.green-border-icon-btn[data-selected]:hover,
.green-border-icon-btn[data-selected]:focus {
    background: #2eadb6;
}

.green-border-icon-btn[filter-data-selected]:hover,
.green-border-icon-btn[filter-data-selected]:focus {
    background: #ffffff00;
}

.green-border-icon-btn[disabled] {
    background: #b9faff !important;
    filter: grayscale(1);
    cursor: default;
}

.green-border-btn[data-selected]:hover,
.green-border-btn[data-selected]:focus {
    background: #7fcfd4;
}

/* End Native green bordered buttons */

.MuiPopover-root,
.MuiTooltip-popper {
    z-index: 4000 !important;
}

.fullsize-fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.full-width {
    width: 100%;
}

.z1 {
    z-index: 1;
}

.z2 {
    z-index: 2;
}

.mb0 {
    margin-bottom: 0 !important;
}
.mb16 {
    margin-bottom: 16px;
}

.mb8 {
    margin-bottom: 8px;
}

.mt3 {
    margin-top: 3px !important;
}

.mt10 {
    margin-top: 10px !important;
}
.mt12 {
    margin-top: 12px !important;
}

.mt16 {
    margin-top: 16px;
}

.ml16 {
    margin-left: 16px;
}

.mt32 {
    margin-top: 32px !important;
}

.pt13 {
    padding-top: 13px !important;
}

.pr16 {
    padding-right: 16px !important;
}

.mt4 {
    margin-top: 4px !important;
}

.mt7 {
    margin-top: 7px !important;
}

.mt6 {
    margin-top: 6px !important;
}

.mt8 {
    margin-top: 8px !important;
}

.mt-8 {
    margin-top: -8px !important;
}

.mt17 {
    margin-top: 17px !important;
}

.mt20 {
    margin-top: 20px !important;
}

.mt24 {
    margin-top: 24px !important;
}

.ml40 {
    margin-left: 40px;
}

.ml20 {
    margin-left: 20px;
}

.ml16 {
    margin-left: 16px;
}

.ml8 {
    margin-left: 8px;
}

.ml5 {
    margin-left: 5px;
}

.mb18 {
    margin-bottom: 18px;
}

.mr6 {
    margin-right: 6px !important;
}

.mr40 {
    margin-right: 40px;
}

.mr20 {
    margin-right: 20px !important;
}

.mr16 {
    margin-right: 16px !important;
}

.mr9 {
    margin-right: 9px;
}

.mr30 {
    margin-right: 30px !important;
}

.ml0 {
    margin-left: 0 !important;
}
.mt0 {
    margin-top: 0 !important;
}

.ml7 {
    margin-left: 7px;
}

.fs14 {
    font-size: 14px !important;
}

.fs16 {
    font-size: 16px !important;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

/* Material ui custom text field style */
.mui-textfield,
.mui-textfield .MuiInputBase-root,
.mui-textfield .MuiOutlinedInput-root {
    height: 48px;
    border-radius: 8px;
}

.mui-textfield .MuiIconButton-root {
    padding: 4px;
    margin-right: 4px;
}

.mui-textfield .MuiInputAdornment-positionEnd {
    margin-left: 1px;
}

.mui-textfield .MuiInputAdornment-root .MuiTypography-root {
    font-size: 14px;
    margin-right: 8px;
}

.mui-textfield .MuiInputLabel-outlined {
    font-size: 14px;
}

.mui-textfield .MuiOutlinedInput-adornedEnd {
    padding-right: 2px;
    font-size: 14px;
}
.mui-textfield input,
.mui-textfield .MuiOutlinedInput-input {
    font-size: 14px;
}

.mui-textfield .MuiInputLabel-outlined {
    transform: translate(14px, 17px) scale(1);
}

.mui-textfield .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -5px) scale(0.81);
}

.mui-textfield .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.25);
}

.mui-textfield.mui-select .MuiOutlinedInput-notchedOutline {
    border-color: #dbe1e8 !important;
    border-width: 1px;
}
.mui-textfield.mui-select .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #f44336 !important;
}
.mui-textfield.mui-select .MuiInputLabel-outlined {
    color: rgba(0, 0, 0, 0.54) !important;
}
.mui-textfield.mui-select .Mui-error.MuiInputLabel-outlined {
    color: #f44336 !important;
}

/* compact design */
.mui-compact .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
}

.mui-compact .MuiIconButton-root {
    margin-right: 1px;
}

.mui-compact .MuiOutlinedInput-input {
    padding: 16px 8px;
    padding-right: 0;
    font-size: 14px;
}

.mui-compact .MuiInputLabel-outlined {
    transform: translate(8px, 17px) scale(1);
}

.mui-compact .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -5px) scale(0.75);
}

/* Mobile */
.mob-toolbar {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    font-weight: 500;
    font-size: 18px;
    color: #fff;
    background-color: #32bdc7;
    z-index: 2;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.06);
    overflow: hidden;
}

.mob-toolbar,
.mob-toolbar-static {
    height: 56px;
    min-height: 56px;
}

.em-main[ios-app] .mob-toolbar {
    font-weight: 500;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
}

.em-main[ios-app] .mob-toolbar,
.em-main[ios-app] .mob-toolbar-static {
    height: 44px;
    min-height: 44px;
}

.mob-toolbar-title {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 16px;
}
.em-main[ios-app] .mob-toolbar-title {
    text-align: center;
    position: absolute;
    top: 1px;
    left: 0;
    right: 0;
    line-height: 44px;
    margin: 0 80px;
}

.mob-toolbar-static {
    display: none;
}

.mob-toolbar-icon {
    width: 56px;
    height: 56px;
    border-radius: 50% !important;
    margin: 0 !important;
    color: #fff !important;
}

.mob-toolbar-icon img {
    width: 17px;
}

.mob-only {
    display: none;
}

/* Mobile */
@media screen and (max-width: 740px) {
    .mob-toolbar,
    .mob-toolbar-static {
        display: flex;
    }
    .desktop-only {
        display: none !important;
    }
    .mob-only {
        display: flex;
    }
    .MuiButton-root:hover {
        background-color: unset;
    }
    .mob-no-padding {
        padding: 0 !important;
    }
    .mui-textfield .MuiIconButton-root {
        padding: 4px;
        margin-right: 2px;
    }
}

.pac-container {
    z-index: 3005;
    border-radius: 2px;
    padding-bottom: 8px;
}
.pac-item {
    padding: 0 8px;
    height: 48px;
    line-height: 48px;
}
.pac-item:first-child {
    border-top: none;
}
.pac-icon {
    margin-top: 14px;
}
.pac-logo:after {
    margin-right: 8px;
}

/*  */

.page-type-dropdown {
    position: relative;
    height: 56px;
    width: 100%;
    border-radius: 8px;
    background-color: #32bdc7;
    box-shadow: 0 1px 16px 5px rgba(117, 117, 136, 0.11);
    color: #fff;
    padding: 12px 22px 12px 20px;
    font-size: 16px;
    font-weight: 900;
    line-height: 22px;
    margin-bottom: 24px;
    cursor: pointer;
}

.page-type-dropdown:active {
    background-color: #2ca6af;
}

.page-type-dropdown > img {
    width: 32px;
    height: 32px;
    filter: brightness(0) invert(1);
    margin-right: 18px;
}

.page-type-dropdown .ser-arrow {
    width: 14px;
    height: auto;
    margin-right: 0;
    filter: none;
}

.page-type-dropdown-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 1px 16px 5px rgba(117, 117, 136, 0.11);
}

.toolbar-ios-back {
    text-transform: none;
    color: #fff;
    margin-left: 6px;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
}
.toolbar-ios-back .ios-back-label {
    display: block;
    margin-left: -8px;
}

.toolbar-clear-btn {
    text-transform: none;
    color: #fff;
    margin-right: 10px;
}

.clear-btn {
    align-self: flex-end;
    margin-top: 26px;
    color: #32bdc7;
    margin-bottom: 5px;
    text-transform: none;
}

.clear-label {
    color: #32bdc7;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    text-transform: none;
}

.clear-btn[disabled] .clear-label {
    color: #d8d8d8;
}
.print-report {
    overflow: auto;
    width: 100%;
    padding-right: 70px;
}
.print-report br {
    display: none;
}
.print-report .signatures br {
    display: block;
}

@media only screen and (max-width: 768px) {
    .mob-toolbar-gap {
        margin-bottom: 45px;
    }

    .mob-gap-15 {
        margin-bottom: 15px;
    }
}

.chip {
    align-items: center;
    background-color: #ffffff;
    border-radius: 16px;
    border: 1px solid #dbdbdb;
    color: #212121;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    margin-right: 8px;
    max-width: 274px;
    overflow: hidden;
    padding-left: 12px;
    padding-right: 12px;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.chip-delete {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    margin-left: 4px;
}
.chip-delete:hover,
.chip-delete:focus {
    background: #cecece;
}

.chip-no-cross {
    padding-right: 10px;
}
.chip-no-cross .chip-delete {
    display: none;
}

.chip-gray-border {
    border: 1px solid #a4a4a4;
    background-color: #fff;
    color: #7c7c7c;
    padding-right: 10px;
    max-width: 420px;
}
.chip-big {
    max-width: 420px;
    padding-right: 10px;
}
.error-txt {
    color: #d92f44;
}

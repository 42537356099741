.input-field {
    position: relative;
    display: block;
    font-size: inherit;
    line-height: inherit;
    min-height: 48px;
}

.if-input {
    border: 0;
    font-size: inherit;
    font-weight: 400;
    line-height: inherit;
    /* color: #4a4a4a; */
    color: inherit;
    outline: 0;
    padding: 0;
    resize: none;
    background: transparent;
    transition: background 0.3s ease-in-out, width 0.15s, height 0.15s;
    min-height: 48px;
}

/* .if-input[has-focus], */
.input-field:hover .if-bottom-bar {
    border-color: rgba(0, 0, 0, 0.25);
}

.if-input-field {
    position: relative;
    padding: 4px 8px;
    border-radius: 8px;
    text-indent: 6px;
    box-sizing: content-box;
}

.if-measure-label {
    padding: 0 0;
    white-space: pre-line;
}

.if-type-date {
    min-width: 6.25rem;
    max-width: 10.3rem;
}

.if-type-time {
    min-width: 1.8125rem;
    max-width: 3.5rem;
}

.if-bottom-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: 1px solid #dbe1e8;
    border-radius: 8px;
    transition: transform 0.2s cubic-bezier(0.46, -0.01, 0.26, 0.79);
    pointer-events: none;
}

.if-input[has-focus] + .if-bottom-bar {
    border: 2px solid #32bdc7;
}

.if-popup {
    position: relative;
    display: none;
    background-color: #fff;
    position: absolute;
    border-radius: 2px;
    opacity: 0;
    transform: translateY(-5px);
    transition: transform 0.3s, opacity 0.2s;
    font-size: 14px;
}

.if-popup[has-focus] {
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.if-popup-date {
    padding: 0.625rem 0.625rem 0.25rem;
    width: 13.0625rem;
}

.if-fixed-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2000;
    cursor: default;
}

.if-lbl-float {
    font-size: inherit;
    color: #848484;
    position: absolute;
    left: 14px;
    top: 15px;
    transition: 200ms;
    pointer-events: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 44px);
}
.if-lbl-float.if-docked {
    top: -8px;
    left: 4px;
    z-index: 1;
    padding: 0 4px;
    transform: scale(0.8);
    background: #fff;
}

.input-field[data-open] .if-docked {
    color: #32bdc7;
}

.input-field[has-error] .if-bottom-bar,
.input-field[has-error] .if-lbl-float {
    border-color: #f44336;
    color: #f44336;
}

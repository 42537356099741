.td-root {
    font-size: 0.875rem;
    line-height: 1.1875rem;
}

.td-selected-icon {
    display: none;
    width: 12px;
    height: 12px;
    margin-left: 6px;
    align-self: center;
}

.td-dropdown-arrow {
    width: 12px;
    height: 12px;
    margin-left: 6px;
    align-self: center;
}
.td-arrow-up {
    transform: rotateZ(180deg);
}

.td-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: space-between;
    transition: 0.2s ease-out;
    overflow: hidden;
}
.td-item > span,
.td-inner-item > span {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.td-item.td-selected .td-selected-icon {
    display: block;
}

.td-count {
    display: none;
}
.td-item.td-selected .td-count {
    display: flex;
}

.td-bottom-bar {
    width: 100%;
    border-top: 1px solid #ececec;
}

.td-list {
    height: 100%;
}

.td-done {
    padding: 4px;
    margin: 8px !important;
    float: right;
}

.td-done-all {
    float: left;
    padding: 4px 8px !important;
    margin-top: 8px !important;
    margin-left: 6px !important;
    font-size: 12px;
}

.td-item-header {
    color: #a4a4a4;
    font-size: 12px;
    margin-left: 12px;
    margin-top: 5px;
}

.td-item-divider {
    width: 100%;
    height: 1px;
    border-top: 1px solid #ececec;
}

.td-counter {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
}
.td-counter:hover,
.td-counter:focus {
    background: #f3f3f3;
}
.td-counter:active {
    background: #ececec;
}

.td-arrow:after {
    content: '';
    display: block;
    position: absolute;
    top: calc(50% - 2.5px);
    right: 10px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #777;
    transition: 0.3s;
}
.td-arrow[is-selected]:after {
    transform: rotateZ(180deg);
}

.td-inner-item {
    /* justify-content: space-between; */
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

html {
    /* font-size: 12px; */
    font-size: 16px;
}

/* @import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap'); */

/* @import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800&display=swap'); */

@font-face {
    font-family: avenir;
    src: url(/public/fonts/AvenirLight.woff2) format('woff2');
    font-weight: 300;
}

@font-face {
    font-family: avenir;
    src: url(/public/fonts/AvenirRoman.woff2) format('woff2');
    font-weight: 400;
}

@font-face {
    font-family: avenir;
    src: url(/public/fonts/AvenirMedium.woff2) format('woff2');
    font-weight: 500;
}

@font-face {
    font-family: avenir;
    src: url(/public/fonts/Avenir-Heavy.woff2) format('woff2');
    font-weight: 900;
}

@font-face {
    font-family: Gantari;
    src: url(/public/fonts/Gantari-Thin.ttf) format('truetype');
    font-weight: 100;
}

@font-face {
    font-family: Gantari;
    src: url(/public/fonts/Gantari-ExtraLight.ttf) format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: Gantari;
    src: url(/public/fonts/Gantari-Light.ttf) format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: Gantari;
    src: url(/public/fonts/Gantari-Regular.ttf) format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: Gantari;
    src: url(/public/fonts/Gantari-Medium.ttf) format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: Gantari;
    src: url(/public/fonts/Gantari-SemiBold.ttf) format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: Gantari;
    src: url(/public/fonts/Gantari-Bold.ttf) format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: Gantari;
    src: url(/public/fonts/Gantari-ExtraBold.ttf) format('truetype');
    font-weight: 800;
}

body {
    margin: 0;
    font-family: 'avenir', sans-serif;
    /* font-family: 'Nunito Sans', sans-serif; */
    /* -moz-osx-font-smoothing: grayscale; */
    /* -webkit-text-size-adjust: 100%; */
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

* {
    font-family: 'avenir', sans-serif !important;
    /* font-family: 'Nunito Sans', sans-serif !important; */
}

html,
body,
#root {
    width: 100%;
    height: 100%;
}

*,
:after,
:before {
    box-sizing: border-box;
}

/* Defining app-wide colors here based on Design System 2023 */
:root {
    --teal100: #dbf6f8;
    --teal200: #b5ecf0;
    --teal300: #63d7e0;
    --teal400: #00bdcb;
    --teal500: #00a0ac;
    --teal600: #00848e;
    --teal700: #006971;
    --turquoise800: #004f55;
    --turquoise900: #00363a;
    --sunshine500: #fbbf24;
    --indigo600: #6366f1;
    --lavender600: #7d4ed2;
    --navy600: #142c50;
    --garden600: #37d27e;
    --aqua100: #ebf5fd;
    --aqua600: #399fed;
    --blush400: #e76bab;
    --tangerine600: #ff960a;
    --cream: #f6f4ed;
    --slate: #212121;
    --grey100: #f6f6f6;
    --grey200: #ededed;
    --grey300: #dbdbdb;
    --grey400: #c8c8c8;
    --grey500: #b6b6b6;
    --grey600: #a4a4a4;
    --grey700: #838383;
    --grey800: #626262;
    --grey900: #424242;
    --errorstate: #fff1f2;
    --pendingstate: #fef3c7;
    --successstate: #dcfce7;
    --neutralstate: #def0fd;
    --selectedstate: #dbf6f8;
    --recommended: #eef2ff;
    --completedstate: #ededed;
    --partnercareappprimary: #2da0ca;
    --partnercareappsecondary: #b2dbe8;
}
